import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import { Link } from "gatsby"
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import {ResourcesContentWrap, ResourcesContentTop, PrivacyContent, ResourcesContentBottom} from './resources-content-area.stc'

const ResourcesContentArea = (props) => {
    const resourcesData = useStaticQuery(graphql `
        query ResourcesContentQuery {
            resourcesJson(id: {eq: "resources-page-content"}) {
                top_content {
                    heading
                    content {
                        text1
                        text2
                    }
                }
                privacy_content {
                    heading
                    content {
                        text1
                        text2
                    }
                }
                bottom_content {
                    heading
                    content {
                        text1
                        text2
                    }
                }
            }
        }
    `)
    const {top_content, bottom_content, privacy_content} = resourcesData.resourcesJson
    const {topHeadingCSS, textCSS} = props;
    return (
        <ResourcesContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        {top_content && (
                            <ResourcesContentTop>
                                {top_content.heading && <Heading {...topHeadingCSS}>{top_content.heading}</Heading>}
                                {top_content.content.text1 && <Text {...textCSS}>{top_content.content.text1}</Text>}
                                {top_content.content.text2 && <Link to="/faq"><Text {...textCSS}>{top_content.content.text2}</Text></Link>}
                            </ResourcesContentTop>
                        )}
                        {privacy_content && (
                            <PrivacyContent>
                                {privacy_content.heading && <Heading {...topHeadingCSS}>{privacy_content.heading}</Heading>}
                                {privacy_content.content.text1 && <Text {...textCSS}>{privacy_content.content.text1}</Text>}
                                {privacy_content.content.text2 && <a href='/Spectrum_House_Privacy_Policy.pdf' target='_blank' rel='noopener noreferrer'><Text {...textCSS}>{privacy_content.content.text2}</Text></a>}
                            </PrivacyContent>
                        )}
                        {bottom_content && (
                            <ResourcesContentBottom>
                                {bottom_content.heading && <Heading {...topHeadingCSS}>{bottom_content.heading}</Heading>}
                                {bottom_content.content.text1 && <Text {...textCSS}>{bottom_content.content.text1}</Text>}
                                {bottom_content.content.text2 && <Text {...textCSS}>{bottom_content.content.text2}</Text>}
                            </ResourcesContentBottom>
                        )}
                    </Col>
                </Row>
            </Container>
        </ResourcesContentWrap>
    )
}

ResourcesContentArea.defaultProps = {
    topHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        lineHeight: 1.375,
        mb: '17px'
    },
    textCSS: {
        mb: '14px'
    },
    listHeadingCSS: {
        as: 'h5',
        mb: '14px'
    }
}

export default ResourcesContentArea


  